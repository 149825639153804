<template>
  <section>
    <vue-headful :title="title" />
    <v-container>
      <v-row no-gutters>
        <v-col cols="8" lg="6" md="6">
          <h1 class="fontD mt-5 pa-3 text-left">{{ dominio }}</h1>
        </v-col>
        <v-col cols="8" lg="6" md="6">
          <v-btn class="ma-2 mt-7 float-lg-right" text color="#616161" dark>
            <router-link :to="{ name: 'admin-dominios' }">
              <v-icon color="#616161" left>
                mdi-arrow-left
              </v-icon>
              <span class="botonNegro">atras</span>
            </router-link>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-card flat>
        <v-alert border="top" colored-border type="info" elevation="2">
          Estimado usuario, en esta sección prodra editar los contactos y
          solicitar cambios de DNS.
        </v-alert>
        <v-alert
          v-if="msgerror"
          border="right"
          colored-border
          type="error"
          elevation="2"
          transition="scale-transition"
        >
          <a class="black--text" v-html="msgerror"></a>
        </v-alert>

        <!-- <v-btn
              class="white--text float-right ma-2"
              depressed
              color="green"
              elevation="2"
               v-on:click="solicitar"
               >Guardar
          </v-btn>
          <v-btn
              class="white--text float-right ma-2"
              depressed
              color="info"
              elevation="2"
              
               >Editar
          </v-btn> -->
        <v-expansion-panels
          background-color="uniColor"
          align-with-title
          multiple
        >
          <!--    <v-tabs-slider color="yellow"></v-tabs-slider> -->
          <v-expansion-panel>
            <v-expansion-panel-header
              ><h3>Datos de Contacto</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <template v-if="contactosList">
                <v-expansion-panel>
                  <v-row align="center">
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="agregarContacto"
                        dense
                        :menu-props="{ top: true, offsetY: true }"
                        color="blue darken-2"
                        placeholder="Agregar contacto"
                        outlined
                        filled
                        chips
                        :items="contactosList"
                        item-text="nombre"
                        item-value="nombre"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon class="mt-n8" v-bind="attrs" v-on="on">
                            <v-icon
                              color="green"
                              v-on:click="addContactoCard(agregarContacto)"
                            >
                              mdi-plus-circle
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Tiene que agrear al menos tres contactos</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-btn
                        outlined
                        class="white--text mr-5"
                        v-bind:color="editBtnColor"
                        v-on:click="enableEditMode"
                        >{{ editBtnLabel }}</v-btn
                      >
                    </v-col>
                    <!-- <v-col cols="12" lg="2">
                      <v-layout class="justify-start">
                              <div class="mt-n6">
                                 
                                  </div>
                          </v-layout>
                    </v-col> -->
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      v-for="(item, i) in cardContactos"
                      :key="'CD' + i"
                    >
                      <v-card elevation="2" outlined>
                        <v-container class="d-flex align-center">
                          <v-layout class="justify-start">
                            <div class="py-0 px-0">
                              <v-card-title>{{ item.name }}</v-card-title>
                            </div>
                          </v-layout>
                          <v-layout class="justify-end">
                            <div class="">
                              <v-btn class="float-right" icon>
                                <v-icon
                                  color="cardActivedanger"
                                  v-on:click="removeContactoCard(item.name)"
                                >
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-layout>
                        </v-container>
                        <v-divider></v-divider>
                        <v-form>
                          <!--INCIO CARD ITEMS-->
                          <v-col
                            cols="12"
                            lg="12"
                            v-for="(itemc, k) in item.contactos"
                            :key="k"
                          >
                            <v-row no-gutters>
                              <v-col cols="12" lg="6">
                                <v-text-field
                                  dense
                                  class="pa-2"
                                  color="blue darken-2"
                                  placeholder="Nombre"
                                  outlined
                                  :disabled="!isEditMode"
                                  :rules="[(v) => !!v || 'Campo Obligatorio']"
                                  v-model="itemc.nombre"
                                  filled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" lg="6">
                                <v-text-field
                                  dense
                                  class="pa-2"
                                  color="blue darken-2"
                                  placeholder="Teléfono1"
                                  outlined
                                  :counter="8"
                                  :disabled="!isEditMode"
                                  type="number"
                                  :rules="[
                                    (v) => !!v || 'Campo Obligatorio',
                                    (v) =>
                                      v.length >= 8 ||
                                      'El debe contener al menos 8 digitos ',
                                  ]"
                                  filled
                                  v-model="itemc.telefono"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="6">
                                <v-text-field
                                  dense
                                  class="pa-2"
                                  color="blue darken-2"
                                  placeholder="Celular"
                                  outlined
                                  :counter="8"
                                  :disabled="!isEditMode"
                                  type="number"
                                  :rules="[
                                    (v) => !!v || 'Campo Obligatorio',
                                    (v) =>
                                      v.length >= 8 ||
                                      'El debe contener al menos 8 digitos ',
                                  ]"
                                  filled
                                  v-model="itemc.celular"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                lg="6"
                                v-for="(input, k) in itemc.correoElectronico"
                                :key="k"
                              >
                                <v-row no-gutters class="">
                                  <v-col cols="12" lg="10">
                                    <v-text-field
                                      dense
                                      class="pa-2"
                                      color="blue darken-2"
                                      outlined
                                      v-model="input.value"
                                      label="Correo"
                                      placeholder="Correo"
                                      :disabled="!isEditMode"
                                      :rules="emailRules"
                                      filled
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" lg="2">
                                    <v-btn icon class="mt-2">
                                      <v-icon
                                        color="green"
                                        v-on:click="add(itemc, k, item.nombre)"
                                        v-show="
                                          k ==
                                            itemc.correoElectronico.length - 1
                                        "
                                      >
                                        mdi-plus-circle
                                      </v-icon>
                                    </v-btn>
                                    <v-btn icon class="mt-2">
                                      <v-icon
                                        color="cardActivedanger"
                                        v-on:click="remove(itemc, k)"
                                        v-show="
                                          k ||
                                            (!k &&
                                              itemc.correoElectronico.length >
                                                1)
                                        "
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-container class="d-flex align-center">
                                <v-layout class="justify-start">
                                  <div class="py-0 px-0  ">
                                    <v-switch
                                      color="green"
                                      v-model="itemc.recibeNotificacion"
                                      :label="
                                        `El cliente recibira notificaciones`
                                      "
                                    ></v-switch>
                                  </div>
                                </v-layout>
                                <!-- <v-layout class="justify-end">
                                  <div class="">
                                    <v-btn
                                        text
                                        v-on:click="solicitar"
                                    >Guardar
                                    </v-btn>
                                  </div>

                                </v-layout> -->
                              </v-container>
                            </v-row>
                          </v-col>
                          <!--                      FIN CARD ITEMS-->
                        </v-form>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><h3>Configuración de DNS</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <!--           INICIO TAB CONFUGURACION DNS-->
              <template v-if="dnsList">
                <v-row align="center">
                  <v-col cols="10" lg="4" md="3">
                    <!-- <v-text-field
                    dense
                    :menu-props="{ top: true, offsetY: true }"
                    color="blue darken-2"
                    label="Agregar DNS"
                    outlined
                    filled
                    disabled
                ></v-text-field> -->
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          class="ma-2"
                          color="success"
                          outlined
                        >
                          <v-icon color="green" v-on:click="addDNSCard()">
                            mdi-plus-circle
                          </v-icon>
                          Agregar DNS
                        </v-chip>
                      </template>
                      <span
                        >Tiene que agregar al menos dos configuración DNS</span
                      >
                    </v-tooltip>
                  </v-col>
                  <v-col cols="2" lg="2" md="3">
                    <!-- <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">       
                    <v-btn icon class="d-flex justify-end  mb-6 pa-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                          color="green"
                          v-on:click="addDNSCard()">
                        mdi-plus-circle
                      </v-icon>
                    </v-btn>
                </template>
                <span>Tiene que agregar al menos dos DNS</span>
                </v-tooltip> -->
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" v-for="(item, i) in cardDNS" :key="'CD' + i">
                    <v-card elevation="2" outlined>
                      <v-container class="d-flex align-center">
                        <v-layout class="justify-start">
                          <div class="py-0 px-0">
                            <v-card-title>{{ item.title }}</v-card-title>
                          </div>
                        </v-layout>
                        <v-layout class="justify-end">
                          <div class="">
                            <v-btn class="float-right" icon>
                              <v-icon
                                color="cardActivedanger"
                                v-on:click="removeDNSCard(item.id)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-layout>
                      </v-container>
                      <v-divider></v-divider>
                      <v-form>
                        <!--                      INCIO CARD ITEMS-->
                        <v-col
                          cols="12"
                          lg="12"
                          v-for="(itemc, k) in item.editar"
                          :key="k"
                        >
                          <v-row no-gutters>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                dense
                                class="pa-2"
                                color="blue darken-2"
                                label="Host"
                                outlined
                                :rules="hostRule"
                                v-model="itemc.ip"
                                v-on:keypress="isCaseOne(dominio, itemc.ip)"
                                filled
                              >
                              </v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              lg="6"
                              v-for="(input, k) in itemc.direccionesIP"
                              :key="k"
                            >
                              <v-row no-gutters class="">
                                <v-col cols="12" lg="10">
                                  <v-text-field
                                    dense
                                    class="pa-2"
                                    color="blue darken-2"
                                    outlined
                                    v-model="input.value"
                                    label="Dirección IP DNS"
                                    :rules="ipDnsRule"
                                    filled
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2" lg="2">
                                  <v-btn
                                    icon
                                    class="d-flex justify-end mt-2 pa-1"
                                  >
                                    <v-icon
                                      color="red"
                                      v-on:click="
                                        pushDireccionIP(itemc, k, input.value)
                                      "
                                      v-show="
                                        k == itemc.direccionesIP.length - 1
                                      "
                                    >
                                      mdi-plus-circle
                                    </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>

                        <!--                      FIN CARD ITEMS-->
                      </v-form>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="d-flex align-end flex-column">
                    <v-btn
                      class="white--text float-right ma-2"
                      outlined
                      color="green"
                      v-on:click="solicitar"
                      >Enviar
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-content>
            <!--           FIN TAB CONFIGURACION DNS -->
          </v-expansion-panel>
          <v-expansion-panel v-if="dias">
            <v-expansion-panel-header
              ><h3>
                Solicitud de cambio de nombre de dominio
              </h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <form action="">
                <v-container fluid>
                  <v-row class="bg">
                    <!-- <v-col class="justify-center" cols="12" md="12" lg="4">
                          <v-text-field
                            label="Nombre actual"
                            v-model="nombreactual"
                            :rules="nombreRules"
                             color="blue darken-2"
                           chips
                          filled
                          
                          ></v-text-field>
                        </v-col> -->
                    <v-col cols="12" md="4" lg="4">
                      <v-text-field
                        v-model="nombreDominio"
                        label="Nuevo nombre"
                        :rules="[(v) => !!v || 'Campo Obligatorio']"
                        color="blue darken-2"
                        required
                        light
                        chips
                        filled
                      ></v-text-field>
                      <!-- <v-textarea
                           label="Observación"
                            v-model="Observacion"
                            :rules="[v => !!v || 'Campo Obligatorio']"
                            color=""
                          >
                          </v-textarea> -->
                      

                      <template v-if="vista === true">
                        <v-select
                          v-model="idZona"
                          item-value="idZona"
                          item-text="zona"
                          id="zonas"
                          placeholder="Dominio"
                          :items="items"
                          :rules="[(v) => !!v || 'Campo Obligatorio']"
                          required
                          color="blue darken-2"
                          light
                          chips
                          filled
                        >
                        </v-select>
                      </template>
                      
                      <template v-if="dominioClean[1] != 'ni'">
                        <v-switch
                          v-model="vista"
                          label="cambiar de zona"
                          color="green"
                          inset
                        ></v-switch>
                      </template>
                      <v-btn
                        class="white--text float-left ma-2"
                        outlined
                        color="green"
                        @click.prevent="solicitarCambioNombre"
                        >Enviar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-container>
  </section>
</template>
<script>
import vueHeadful from "vue-headful";
import Vue from "vue";
export default {
  components: {
    vueHeadful,
  },

  async mounted() {
    this.getZonas();
    this.separadorDominios();
    this.getdomioDetalle();
    this.param = this.$route.params.id;
    (this.dominio = this.$route.params.dominio),
      (this.dias = await this.days());
    await this.getContactoTipo();
    // await this.defaultValueCardContactos();
    await this.getContactos();
    // await this.getDns();
  },
  data: () => ({
    vista: false,
    dominioClean: [],
    idZona: 0,
    dias: false,
    zona: "",
    zonaId: 0,
    items: [],
    contactosGet: [],
    eliminarContacto: [],
    nombreactual: "",
    isEditMode: false,
    editBtnLabel: "Editar",
    editBtnColor: "green",
    isEditarMode: false,
    editarBotonColor: "green",
    editarBtnLabel: "Editar",
    msgerror: "",
    listahost: [
      {
        icon: "mdi-delete",
        text: 121211212,
      },
      {
        icon: "mdi-delete",
        text: 121211212,
      },
      {
        icon: "mdi-delete",
        text: 121211212,
      },
    ],
    nuevoNombre: {
      idClienteDominio: 0,
      nombreDominio: "",
      idZona: 0,
      strObservacion: "",
    },

    nombreDominio: "",
    Observacion: "",
    contactosListPut: {
      idContacto: 0,
      idTipoContacto: 0,
      tipoContacto: "",
      nombre: "",
      telefono: "",
      celular: "",
      correoElectronico: "",
    },
    dnsListPut: {
      idClienteDominioDns: 0,
      idDns: 0,
      host: "",
      ip: [],
    },
    dns: {
      id: 0,
      idDns: 0,
      host: "",
      estado: "",
      ip: [
        {
          ipdnsIp: 0,
          ip: "",
        },
      ],
    },
    param: 0,
    idClienteDominio: 0,
    idDetallePedido: 0,
    host: "",
    hostTitle: "",
    ip: "",
    reg: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
    hostReg: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.){2,}$/,
    ipRegx: /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/,
    autoRenovacion: true,
    tab: null,
    nombreRules: [
      (v) => !!v || "Nombre es obligatorio",
      // v => v.length <= 10 || 'Name must be less than 10 characters',
    ],
    itemsi: ["Configuración DNS", "Datos de Contacto"],
    items2: [
      { title: "Contacto administrativo" },
      { title: "Contacto técnico" },
      { title: "Contacto de pago del dominio virtual" },
    ],
    dnsItems: [
      {
        cardTitle: "Configuración DNS",
      },
    ],
    ips: [],
    agregarContacto: "",
    tipoContacto1: {
      name: "Contacto Técnico",
    },
    title: "Editar Dominio",
    dominio: "",
    hostRule: [
      (v) => !!v || "el host es requerido",
      (v) =>
        /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.){2,}$/.test(
          v
        ) || "Host no es valido",
    ],
    ipDnsRule: [
      (v) => !!v || "debe de ingresar una direccion IP",
      (v) =>
        /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/.test(
          v
        ) || "IP DNS no es valido",
    ],
    emailRules: [
      (v) => !!v || "Correo es obligatorio",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "Correo debe ser valido",
    ],
    cardContactos: [],
    cardDNS: [],
    cardContactoTipo: [],
    cardContactoTipoHeader: "",
    dnsList: [],
    contactosList: [],
    dnsListClone: [],
    ipErr: false,
    hostErr: false,
    crearDns: [],
    tempArray: [],
    isDisabled: true,
    idZonaObtenida: 0,
    idCientDOm: 0,
  }),

  methods: {
    separadorDominios: async function(){
      let nombre = localStorage.getItem("nombreDominio");
      let dominioFilter1 = nombre.split(".");
      this.dominioClean = dominioFilter1;
      console.log("prueba visualizacion: ", this.dominioClean[1]);

      this.idCientDOm = parseInt(window.localStorage.getItem("idCienteDom"));
      
    },
    getdomioDetalle: async function() {
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;

      
      await this.$http
        .get(
          this.$keys("CLIENTEDETALLEDOM") + "/" + this.idCientDOm )
        .then((resp) => {
          if (resp.status == 200) {

            console.log("Id cliente dominio: ", resp.data);

          }
        });
    },
    // trae las zonas
    toggle() {
      var index = this.items.findIndex((x) => x.idZona === this.zonaId);
      if (index > -1) {
        this.$store.commit("searchDomain", this.domainRequest);
        this.$store.commit("searchZone", this.items[index].zona);
        this.$store.commit("searchZoneId", this.items[index].idZona);
     
      }
    },
    getZonas: async function() {
      await this.$http
        .get(this.$keys("ZONAS"), {
          headers: {
            "Access-Control-Allow-Origin": "true",
          },
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.items = resp.data;
          }
        });

      this.zonaId = this.items[0].idZona;
       console.log("id zona: ", this.idZona);
    },
    isCaseOne: function(host, value) {
      //el host es el mismo dominio que se compro, en este caso la ip es obligatoria
      if (host.includes(value)) {
        this.isDisabled = false;
      }
      console.log(this.isDisabled);
    },
    defaultValueCardContactos: async function() {
      let def = this.contactosList.filter(
        (x) =>
          x.nombre.toLowerCase().includes("pago") ||
          x.nombre.toLowerCase().includes("cnico") ||
          x.nombre.toLowerCase().includes("administrativo")
      );
      def.forEach((x) => this.addContactoCard(x.nombre));
      for (var i = 0; i < def.length; i++) {
        for (let j = 0; j < this.contactosList.length; j++) {
          if (this.contactosList[j].nombre === def[i].nombre) {
            this.contactosList.splice(j, 1);
          }
        }
      }
    },
    defaultValueDns: async function() {
      let defi = this.dnsList.filter((x) => x.host || x.ip);
      defi.forEach((x) => this.addDNSCard(x.host));
      for (var i = 0; i < defi.length; i++) {
        for (let j = 0; j < this.dnsList.length; j++) {
          if (this.dnsList[j].host === defi[i].host) {
            this.dnsList.splice(j, 1);
          }
          console.log(this.dnsList, "aqui esta la info");
        }
      }
    },
    toasterMessageResponse: function(msg, type) {
      Vue.$toast.open({
        message: msg,
        type: type,
      });
    },
    // solicita cambio de configuracion DNS
    solicitar: async function() {
      //cuando esta eb estad suspendido y lo quiere pagar otra vez: la anaulidad(costo dominio) y la reactivacion (arancel no existe aun)
      let hosting = [];
      for (let i = 0; i < this.cardDNS.length; i++) {
        let ipsss = [];
        for (
          let j = 0;
          j < this.cardDNS[i].editar[0].direccionesIP.length;
          j++
        ) {
          ipsss.push(this.cardDNS[i].editar[0].direccionesIP[j].value);
        }
        hosting.push({ host: this.cardDNS[i].editar[0].ip, ip: ipsss });
      }
      // let contactos = []
      // for (let i = 0; i < this.cardContactos.length; i++) {
      //   contactos.push(this.cardContactos[i].contactos[0])
      // }
      let send = {
        crearDns: hosting,
      };
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .post(this.$keys("SOLICITUD_CAMBIO_DNS") + "/" + this.param, send)
        .then((response) => {
          if (response.status == 200) {
            this.toasterMessageResponse(response.data.mensaje, "success");
            this.isEditMode = false;
            this.editBtnLabel = "Editar";
          }
          this.$router.push("/panel/dominios");
        })
        .catch((error) => {
          this.msgerror = "";

          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              this.msgerror = this.msgerror + "<br>" + key;
              var value = error.response.data.errors[key];
              console.log(value);

              value.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }
          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }
          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (this.msgerror) {
              this.msgerror = this.msgerror + "<br>" + erroresback2?.mensaje;
            } else {
              this.msgerror = erroresback2?.mensaje;
            }
          }
        });
    },

    // solicitar cambio nombre de dominio
    solicitarCambioNombre: async function() {
      let nuevoNombre = [];
      for (let i = 0; i < this.nuevoNombre.length; i++) {
        nuevoNombre.push(this.nuevoNombre[i].nuevoNombre[0]);
      }
      let send = {}
      if (this.vista == true) {
        send = {
          idClienteDominio: this.param,
          nombreDominio: this.nombreDominio,
          idZona: this.idZonaObtenida,
          strObservacion: "el usuario solicita cambio de nombre de dominio",
        };
      }else{
        send = {
          idClienteDominio: this.param,
          nombreDominio: this.nombreDominio,
          idZona: this.idZona,
          strObservacion: "el usuario solicita cambio de nombre de dominio",
        };
      }
      
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .post(this.$keys("SOLICITUD_CAMBIO_DOMINIO"), send)
        .then((response) => {
          if (response.status == 200) {
            this.toasterMessageResponse(response.data.mensaje, "success");
          }
          console.log();
          this.overlay = true;
          this.$router.push("/panel/dominios");
        })
        .catch((error) => {
          this.msgerror = "";

          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              this.msgerror = this.msgerror + "<br>" + key;
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (this.msgerror) {
              this.msgerror = this.msgerror + "<br>" + erroresback2?.mensaje;
            } else {
              this.msgerror = erroresback2?.mensaje;
            }
          }
          this.overlay = true;
        });
    },

    add(arreglo, index, mail) {
      arreglo.correoElectronico.push({
        value: mail,
      });
    },
    pushDireccionIP(arreglo) {
      arreglo.direccionesIP.push({
        value: "",
      });
    },
    addDireccionIP(arreglo, index, value) {
      let valid = this.isValidIP(value);
      let ip = [];
      if (valid) {
        let index = this.crearDns.findIndex((x) => x.host === arreglo.ip);
        if (index < 0) {
          ip.push(value);
          this.crearDns.push({
            host: arreglo.ip,
            ip,
          });
        } else {
          console.log("index : ", index, this.crearDns[index].ip);
          ip.push(value);
          this.crearDns[index].ip.splice(index, 0, value);
        }
      }
    },
    removeIP(arreglo, index) {
      arreglo.direccionesIP.splice(index, 1);
    },
    remove(arreglo, index) {
      arreglo.correoElectronico.splice(index, 1);
    },
    getContactoTipo: async function() {
      var token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(this.$keys("CONTACTO_TIPO"))
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            this.contactosList = response.data;
          }
        })
        .catch();
    },
    //  dns
    // getDns: async function(){
    //   const token =localStorage.getItem(atob("usr"))
    //   this.$http.defaults.headers.common.Authorization = "Bearer " + token
    //   await this.$http
    //             .get(this.$keys('SOLICITUD_CAMBIO_DNS')+ '/' +this.param, dnsListPut)
    //             .then((response) => {
    //               if(response.status === 200){
    //                 return response.data.cardDNS
    //               }
    //             })
    //             .catch(() =>{
    //               return true
    //             })

    // },
    // Obtiene los contactos ,
    getContactos: async function() {
      var token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(this.$keys("DOMINIOS") + "/" + this.param + "/Contactos")
        .then((response) => {
          if (response.status === 200) {
            this.cardContactos = [];
            for (let i = 0; i < response.data.length; i++) {
              this.cardContactos.push({
                name: response.data[i].tipoContacto,
                contactos: [response.data[i]],
              });
              // this.contactosGet.push()
              let index = this.contactosList.findIndex(
                (x) => x.nombre === response.data[i].tipoContacto
              );
              this.contactosList.splice(index, 1);
            }
          }
          console.log(response.data);
        })
        .catch();
    },

    days: async function() {
      const token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      const re = await this.$http
        .get(this.$keys("DOMINIOS") + "/detalle/true/" + this.param)
        .then((response) => {
          if (response.status === 200) {
            return response.data.ynSolicitudCambioDominio;
          }
        })
        .catch(() => {
          return true;
        });
      return re;
    },

    // actualizar DNS
    putDns: async function() {
      let dns = [];
      let agregarDns = [];

      for (let i = 0; i < this.cardDNS.length; i++) {
        dns.push(this.cardDNS[i].dns[0]);
      }
      agregarDns = dns.filter((dns) => dns.idDns === undefined);
      console.log("nuevo", agregarDns);
      let send = {
        agregarDns: agregarDns,
      };
      let get = await this.send(send);
      if (get.status == 200) {
        this.toasterMessageResponse(get.data.mensaje, "success");
      }
    },
    async send(dnsListPut) {
      var token = localStorage.getItem(atob("usr"));

      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      const send = await this.$http
        .put(this.$keys("SOLICITUD_CAMBIO_DNS") + "/" + this.param, dnsListPut)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.msgerror = "";

          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              this.msgerror = this.msgerror + "<br>" + key;
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (this.msgerror) {
              this.msgerror = this.msgerror + "<br>" + erroresback2?.mensaje;
            } else {
              this.msgerror = erroresback2?.mensaje;
            }
          }
          this.overlay = true;
        });
      return send;
    },

    // actualiza los contactos
    putContactos: async function() {
      /*   let hosting = [];
       for (let i = 0; i < this.cardDNS.length; i++) {
         let ipsss = []
          for (let j = 0; j <  this.cardDNS[i].detalle[0].direccionesIP.length; j++) {
             ipsss.push(this.cardDNS[i].detalle[0].direccionesIP[j].value);
          }
        hosting.push({host : this.cardDNS[i].detalle[0].ip, ip : ipsss})
      }  */
      let contactos = [];
      let agregarContacto = [];
      let actualizarContacto = [];
      for (let i = 0; i < this.cardContactos.length; i++) {
        contactos.push(this.cardContactos[i].contactos[0]);
      }

      agregarContacto = contactos.filter(
        (contacto) => contacto.idContacto === undefined
      );
      actualizarContacto = contactos.filter(
        (contacto) => contacto.idContacto > 0
      );
      console.log("Esto es nuevo ", agregarContacto);
      console.log("Esto es viejo ", actualizarContacto);
      let send = {
        agregarContacto: agregarContacto,
        actualizarContacto: actualizarContacto,
      };

      let get = await this.Send(send);

      if (get.status == 200) {
        this.toasterMessageResponse(get.data.mensaje, "success");
        this.isEditMode = false;
        this.editBtnLabel = "Editar";
        this.$router.push("/panel/dominios");
      }
    },

    async Send(contactosListPut) {
      var token = localStorage.getItem(atob("usr"));

      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      const send = await this.$http
        .put(
          this.$keys("DOMINIOS") + "/" + this.param + "/Contactos",
          contactosListPut
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.msgerror = "";

          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              this.msgerror = this.msgerror + "<br>" + key;
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (this.msgerror) {
                  this.msgerror = this.msgerror + "<br>" + msg;
                } else {
                  this.msgerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (this.msgerror) {
              this.msgerror = this.msgerror + "<br>" + erroresback2?.mensaje;
            } else {
              this.msgerror = erroresback2?.mensaje;
            }
          }
          this.overlay = true;
        });
      return send;
    },
    enableEditMode: function() {
      if (this.isEditMode === true) {
        if (this.putContactos());
      }
      this.isEditMode = true;
      this.editBtnColor = "blue";
      this.editBtnLabel = "Guardar Cambios";
    },
    habilitarEditarDns: function() {
      if (this.isEditMode === true) {
        if (this.putDns());
      }
      this.isEditarMode = true;
      this.editarBotonColor = "blue";
      this.editarBtnLabel = "Guardar Cambios";
    },

    isValidIP: function(value) {
      return this.ipRegx.test(value);
    },
    addContactoCard: function(value) {
      let index = this.contactosList.findIndex((x) => x.nombre === value);
      let id = this.contactosList[index].idContactoTipo;
      this.cardContactos.push({
        name: value,
        contactos: [
          {
            idTipoContacto: id,
            nombre: "",
            telefono: "",
            celular: "",
            correoElectronico: [
              {
                value: "",
              },
            ],
            recibeNotificacion: false,
          },
        ],
      });
      this.contactosList.splice(index, 1);
    },
    removeContactoCard: async function(value) {
      const index = this.cardContactos.findIndex((x) => x.name === value);
      const Contacto = this.cardContactos.filter((x) => x.name === value)[0]
        .contactos[0];

      this.eliminarContacto = [Contacto.idContacto];

      let send = {
        eliminarContacto: this.eliminarContacto,
      };

      let get = await this.Send(send);

      if (get.status == 200) {
        this.cardContactos.splice(index, 1);
        this.contactosList.push({
          nombre: value,
          idTipoContacto: Contacto.idTipoContacto,
        });
      } else {
        this.msgerror = "";

        if (get.response.data?.errors) {
          for (var key in get.response.data.errors) {
            this.msgerror = this.msgerror + "<br>" + key;
            var value3 = get.response.data.errors[key];
            // console.log(value);

            value3.forEach((msg) => {
              if (this.msgerror) {
                this.msgerror = this.msgerror + "<br>" + msg;
              } else {
                this.msgerror = msg;
              }
            });
          }
        }

        if (get.response.data?.mensaje) {
          let erroresback = JSON.parse(get.response.data?.mensaje);
          for (var key2 in erroresback.errors) {
            var value2 = erroresback.errors[key2];

            value2.forEach((msg) => {
              if (this.msgerror) {
                this.msgerror = this.msgerror + "<br>" + msg;
              } else {
                this.msgerror = msg;
              }
            });
          }
        }

        if (get.response.data?.mensaje) {
          let erroresback2 = JSON.parse(get.response.data?.mensaje);

          if (this.msgerror) {
            this.msgerror = this.msgerror + "<br>" + erroresback2?.mensaje;
          } else {
            this.msgerror = erroresback2?.mensaje;
          }
        }
      }
    },
    removeDNSCard: function(value) {
      const index = this.cardDNS.findIndex((x) => x.id === value);
      this.cardDNS.splice(index, 1);
    },
    addDNSCard: function() {
      this.cardDNS.push({
        title: "Configuración DNS",
        id: this.cardDNS.length + 1,
        editar: [
          {
            ip: "",
            direccionesIP: [
              {
                value: "",
              },
            ],
          },
        ],
      });
    },
    availableDomainDays: function(fechaVencimiento) {
      var current = new Date();
      var dt1 = new Date(fechaVencimiento);
      let result = Math.floor(
        (Date.UTC(
          current.getFullYear(),
          current.getMonth(),
          current.getDate()
        ) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
      return result;
    },
  },
};
</script>
<style>
.fontD {
  color: #616161 !important;
  font-weight: 600;
  font-size: 36px !important;
}

.botonNegro {
  color: #616161;
  text-decoration: none;
}

.v-card {
  margin-bottom: 1rem;
}

.theme--light.v-label {
  color: #616161 !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #fff !important;
}

.ph {
  margin-top: -20px !important;
}
.theme--light.v-select .v-select__selection--comma {
  color: #000 !important;
}
.mdi-menu-down::before {
  content: "\F035D";
  color: #000 !important;
}
</style>
